import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "@carbon/icons-react";

const ParagraphSectionheading = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-sectionheading": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="inner-wrapper">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-15">
              <h2
                id={content.fieldHeading.toLowerCase().replace(/\W/g, "-")}
                className="section-heading"
              >
                {content.fieldHeading}
              </h2>
            </div>
            {content.fieldSetAnchorLink && (
              <div className="col-1">
                <a
                  className="anchor"
                  href={`#${content.fieldHeading
                    .toLowerCase()
                    .replace(/\W/g, "-")}`}
                >
                  <Link size={32} color="black" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphSectionheading.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldSetAnchorLink: PropTypes.bool,
  }),
};

export default ParagraphSectionheading;
