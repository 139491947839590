import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {isOnlineEvent} from "@lib/is-online-event";

const EventInfoDetailedLocation = ({ content }) => {
  const isLiveStream = isOnlineEvent(content) && content.fieldOnlineEventInfo,
    gridElementClassNames = classNames({
      "grid-element detailed-location": true,
      "livestream not-bold": isLiveStream,
  });

  if (content.fieldLocation || isLiveStream) {
    return (
      <div className={gridElementClassNames}>
        <div className="icon-wrapper">
          <FontAwesomeIcon icon="fa-solid fa-location-dot" />
        </div>

        {content.fieldLocation ? (
          <div className="address">
            {(content.fieldLocation?.addressLine1 || content.fieldLocation?.addressLine2) &&
              <div className="address-lines">
                {content.fieldLocation?.addressLine1}{" "}{content.fieldLocation?.addressLine2}
              </div>
            }
            {(content.fieldLocation?.postalCode && content.fieldLocation?.locality) &&
              <div className="city">
                {content.fieldLocation?.postalCode}{" "}{content.fieldLocation?.locality}
              </div>
            }
          </div>
        ) : (
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: content?.fieldOnlineEventInfo }}
          />
        )}


      </div>
    );
  }
}

export const teaserEventPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldLocation: PropTypes.string,
  fieldShowDuration: PropTypes.bool,
  fieldText: PropTypes.string.isRequired,
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  fieldDate: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldTicket: PropTypes.shape({
    uri: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

EventInfoDetailedLocation.propTypes = {
  item: teaserEventPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default EventInfoDetailedLocation;