import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DateTime from "./../../../../date-time/date-time"

const TimeInfo = ({ content }) => {
  return (
    <>
      <div className="icon-wrapper">
        <FontAwesomeIcon icon="fa-solid fa-clock" />
      </div>

      <span>
        <DateTime
          value={content.fieldDate.value}
          endValue={content.fieldDate.endValue}
          granularity="time"
          mode="short"
          showEnd={true}
        />
      </span>
    </>
  );
}

export default TimeInfo;