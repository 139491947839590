import React from "react";
import PropTypes from "prop-types";
import RelatedQuery from "./related.graphql";
import { useQuery } from "@apollo/client";
import TeaserBase from "@teasers/teaser-base";
import { FormattedMessage } from "react-intl";
import ErrorBoundary from "@js/error-boundary";
import Slider from "react-slick";
import CustomGalleryArrow from "@general-components/ui/custom-icons/custom-gallery-arrow";
import classNames from "classnames";

const Related = ({ nodeContent, contentType }) => {
  const { data, loading, error } = useQuery(RelatedQuery, {
    variables: {
      contentType,
      skipNid: nodeContent?.id,
      tags: [nodeContent?.fieldEventType?.id] || [],
    },
  });

  const nodes = data?.entityById?.executable?.execute?.rows || [];

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (nodes.length === 0) return null;

  const sliderSettings = {
    arrows: true,
    dots: false,
    infinite: false,
    centerPadding: "32px",
    centerMode: false,
    nextArrow: <CustomGalleryArrow classNames={classNames} orientation={"right"}/>,
    prevArrow: <CustomGalleryArrow classNames={classNames} orientation={"left"}/>,
    slidesToScroll: 1,
    slidesToShow: 3.5,
    touchMove: true,
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 2.5
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.5
        },
      }
    ],
  };

  return (
    <section className="related-content">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>
              <FormattedMessage id="related.headline" />
            </h2>
          </div>
        </div>
        <div className="row">
          <ErrorBoundary>
            <Slider {...sliderSettings}>
              {nodes.map((node) => (
                <TeaserBase item={node} key={node.id} />
              ))}
            </Slider>
          </ErrorBoundary>
        </div>
      </div>
    </section>
  );
};

Related.propTypes = {
  nodeContent: PropTypes.object.isRequired,
  contentType: PropTypes.arrayOf(
    PropTypes.oneOf(["article", "event", "news", "person", "project"])
  ).isRequired,
};

export default Related;
