import React from "react";
import CustomArrow from "./custom-arrow";
import PropTypes from "prop-types";

const CustomGalleryArrow = ({ onClick, className, orientation }) => (
  <div className={className} onClick={onClick}>
    <CustomArrow orientation={orientation}/>
  </div>
);

CustomGalleryArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default CustomGalleryArrow;
