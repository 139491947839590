import React from "react";
import useAuthentication from "../use-authentication";
import CardUser from "./components/card-user";
import CardMemberActions from "./components/card-member-actions";
import CardSubmissions from "./components/card-submissions";

const PageDashboard = () => {
  const { auth } = useAuthentication();
  const currentUser = auth?.currentUser;

  return <CardSubmissions view="event_submissions" />;
};

PageDashboard.propTypes = {};

export default PageDashboard;
