import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const EditButton = () => {
  return (
    <button
      className="action-element edit"
      data-color-scheme={"edit"}
      role="edit"
    >
      <FontAwesomeIcon icon={`fa-solid fa-pencil`} />
    </button>
  );
}