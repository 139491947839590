import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import Tags from "../../../tags/tags";
import Image from "../../../image/image";
import RandomPlaceholderImage from "./../../../random-placeholder-image";
import { animateScroll as scroll } from "react-scroll";

// Action-Wrapper Elements
import LocationInfo from "./components/location-info";
import OnlineInfo from "./components/online-info";
import AddToCalendar from "./components/add-to-calendar";
import TimeInfo from "./components/time-info";
import ShareElement from "./components/share-element";
import DateTime from "@general-components/date-time/date-time";
import {isOnlineEvent} from "@lib/is-online-event";

moment.locale("de");

/**
 * Usually the first module on an event page - event intro module with most
 * important information.
 */
const ParagraphEventInformation = ({ nodeContent }) => {

  const scrollToInfoBlock = () => {
    let scrollToElement = document.querySelector(".event-info-block");

    if (scrollToElement) {
      scroll.scrollTo(scrollToElement.offsetTop - 100, { delay: 0, duration: 500, smooth: 'easeOutQuad' });
    }

  }

  const sectionClassNames = classNames({
    "paragraph paragraph-event-information": true,
    "with-image": nodeContent.fieldImage,
    "without-image": !nodeContent.fieldImage
  });

  const infoBlockClassNames = classNames({
    "col-16 info-block": true,
    "col-lg-7": nodeContent.fieldImage
  });

  return (
    <section className={sectionClassNames}>
      <div className="intro">

        <RandomPlaceholderImage
          type={"event-background"}
          classNames={"event-placeholder-background"}
        />

        <div className="container bigger">
          <div className="row">
            <div className={infoBlockClassNames}>
              <div className="date-wrapper">
                <DateTime
                  value={nodeContent.fieldDate.value}
                  endValue={nodeContent.fieldDate.endValue}
                  granularity="date"
                  mode="short"
                  showEnd={true}
                />
              </div>
              <Tags tags={nodeContent.fieldEventType} />
              <h1 className="headline-lg">{nodeContent.title}</h1>
            </div>
          </div>
        </div>

        {nodeContent.fieldImage && nodeContent.fieldImage?.fieldMediaImage &&
          <div className="image-block">
            <Image
              data={nodeContent.fieldImage.fieldMediaImage}
            />
          </div>
        }
      </div>

      <div className="action-wrapper">
        <div className={"container"}>
          <div className="row">
            {(nodeContent.fieldLocation || isOnlineEvent(nodeContent)) &&
              <button
                className="col location"
                onClick={scrollToInfoBlock}
              >
                <LocationInfo content={nodeContent}/>
                {!nodeContent.fieldLocation &&
                  <OnlineInfo content={nodeContent}/>
                }
              </button>
            }

           <button
             className="col time"
             onClick={scrollToInfoBlock}
           >
             <TimeInfo content={nodeContent}/>
           </button>

           <div className="col share">
             <ShareElement content={nodeContent}/>
           </div>

           <div className="col atc">
             <AddToCalendar content={nodeContent} />
           </div>

            {/*<div className="col register">
             <a
               href={getEncodedIcalUrl(
                 nodeContent.fieldDate,
                 nodeContent.fieldLocation || "",
                 nodeContent.title,
                 nodeContent.fieldText || ""
               )}
               className="btn btn-primary"
             >
               <FormattedMessage id="event.register" />
             </a>
           </div>*/}

          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphEventInformation.propTypes = {
  content: PropTypes.object,
  nodeContent: PropTypes.shape({
    title: PropTypes.string,
    fieldShowDuration: PropTypes.bool,
    fieldDate: PropTypes.shape({
      value: PropTypes.string,
      endValue: PropTypes.string,
    }),
    fieldLocation: PropTypes.string,
    fieldTags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    fieldText: PropTypes.string,
    fieldTicket: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};

export default ParagraphEventInformation;
