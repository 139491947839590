import React from "react";

const CustomPlay = () => {
  return (
    <div
      className={"custom-icon play"}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="121"
        height="121"
        fill="none"
        viewBox="0 0 121 121"
      >
        <path
          fill="#03284F"
          fillRule="evenodd"
          d="M60.5 121c33.413 0 60.5-27.087 60.5-60.5S93.913 0 60.5 0 0 27.087 0 60.5 27.087 121 60.5 121m31.6-56.048c2.64-1.544 2.64-5.36 0-6.904L48.52 32.547c-2.666-1.56-6.02.363-6.02 3.452v51.002c0 3.09 3.354 5.013 6.02 3.452z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
}

export default CustomPlay;