import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { DEFAULT_LANGUAGE } from "@js/i18n/use-i18n";
import { createDateAsUTC, isFullDay } from "@lib/date-time-helper";

/**
 * All options for the date and time formats.
 * Comment out the options you don't need. Then they won't be included in the output.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#syntax
 */
const FORMAT_OPTIONS = {
  short: {
    minute: "2-digit",
    hour: "2-digit",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  },
  long: {
    minute: "2-digit",
    hour: "numeric",
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
  }
};

const DateTime = ({
  value,
  endValue = null,
  showEnd = true,
  mode = "short",
  granularity = "datetime",
}) => {
  let language = useSelector((state) => state.language);

  if (!language || language === "und") {
    language = DEFAULT_LANGUAGE;
  }

  let startDate, endDate, formatOptions = {};

  if (!showEnd || !endValue) {
    startDate = createDateAsUTC(new Date(value));
  } else {
    startDate = createDateAsUTC(new Date(value));
    endDate = createDateAsUTC(new Date(endValue));
  }

  // Don't show time, if granularity is set to date only
  if (granularity === "date") {
    formatOptions = {
      day: FORMAT_OPTIONS[mode].day,
      month: FORMAT_OPTIONS[mode].month,
      year: FORMAT_OPTIONS[mode].year,
      weekday: FORMAT_OPTIONS[mode].weekday,
    };
  }

  // Don't show date, if granularity is set to time only
  if (granularity === "time") {
    formatOptions = {
      hour: FORMAT_OPTIONS[mode].hour,
      minute: FORMAT_OPTIONS[mode].minute,
    };
  }

  // show everything, if granularity is set to datetime
  if (granularity === "datetime") {
    formatOptions = {
      ...FORMAT_OPTIONS[mode],
    };
  }

  // Don't show time, if it's a full day event
  if (isFullDay(startDate, endDate)) {
    formatOptions.hour = undefined;
    formatOptions.minute = undefined;
  }

  const dateTimeFormat = new Intl.DateTimeFormat(language, {
    ...formatOptions,
  });

  if (!showEnd || !endDate) {
    return <time>{dateTimeFormat.format(startDate)}</time>;
  }

  return <time>{dateTimeFormat.formatRange(startDate, endDate)}</time>;
};

DateTime.propTypes = {
  /**
   * The start date/time of the range.
   */
  value: PropTypes.string.isRequired,
  /**
   * The end date/time of the range. If not provided, only the start date is shown.
   */
  endValue: PropTypes.string,
  /**
   * The granularity of the date format.
   */
  granularity: PropTypes.oneOf(["time", "date", "datetime"]),
  /**
   * Whether to show the end date/time or not.
   */
  showEnd: PropTypes.bool,
  /**
   * The mode of the date format.
   */
  mode: PropTypes.oneOf(["short", "long"]),
};

export default DateTime;
