import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DateTime from "@general-components/date-time/date-time";

const EventInfoDetailedTime = ({ content }) => {
  return (
    <div className="grid-element detailed-time">
      <div className="icon-wrapper">
        <FontAwesomeIcon icon="fa-solid fa-clock" />
      </div>

      <DateTime
        value={content.fieldDate.value}
        endValue={content.fieldDate.endValue}
        granularity="datetime"
        mode="long"
        showEnd={true}
      />
    </div>
  );
}

export const teaserEventPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldLocation: PropTypes.string,
  fieldShowDuration: PropTypes.bool,
  fieldText: PropTypes.string.isRequired,
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  fieldDate: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldTicket: PropTypes.shape({
    uri: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

EventInfoDetailedTime.propTypes = {
  item: teaserEventPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default EventInfoDetailedTime;