import React from "react";
import PropTypes from "prop-types";
import ScrollSpy from "react-scrollspy-navigation";

const InPageNavigation = (props) => {
  const paragraphGroups = props.paragraphGroups.filter((paragraphGroup) => {
    if (!paragraphGroup.section) return false;
    return true;
  });

  if (!paragraphGroups.length) return null;

  paragraphGroups.forEach((paragraphGroup) => {
    paragraphGroup.label =
      paragraphGroup.section.fieldAnchor || paragraphGroup.section.fieldHeading;
    paragraphGroup.id = props.getGroupId(paragraphGroup);
  });

  return (
    <div className="in-page-navigation-wrapper">
      <div className="in-page-navigation container">
        <ScrollSpy activeClass="active" offsetTop={250}>
          <nav>
            <ul className="in-page-navigation-list">
              {paragraphGroups.map((paragraphGroup, i) => (
                <li key={i} className="in-page-navigation-item">
                  <a href={"#" + paragraphGroup.id}>{paragraphGroup.label}</a>
                </li>
              ))}
            </ul>
          </nav>
        </ScrollSpy>
      </div>
    </div>
  );
};

InPageNavigation.propTypes = {
  paragraphGroups: PropTypes.arrayOf(
    PropTypes.shape({
      section: PropTypes.shape({
        fieldAnchor: PropTypes.string,
        fieldHeading: PropTypes.string.isRequired,
      }),
      items: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  getGroupId: PropTypes.func.isRequired,
};

export default InPageNavigation;
