const FULL_DAY_START = "00:00";
const FULL_DAY_END = "23:59";

/**
 * Checks if the given range is a full day
 *
 * @param {Date} start
 * @param {Date} end
 * @returns
 */
export const isFullDay = (start, end) => {
  const _startTime =
    start &&
    start.toLocaleTimeString("de", {
      hour: "2-digit",
      minute: "2-digit",
    });

  const _endTime =
    end &&
    end.toLocaleTimeString("de", {
      hour: "2-digit",
      minute: "2-digit",
    });

  const startsAtMidnight = _startTime && _startTime === FULL_DAY_START;
  const endsAtMidnight = _endTime && _endTime === FULL_DAY_END;

  if (!end) {
    return startsAtMidnight;
  } else {
    return startsAtMidnight && endsAtMidnight;
  }
};

/**
 * Convert a date to UTC
 *
 * @param {Date} date The date to convert to UTC
 * @returns
 */
export const createDateAsUTC = (date) => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
};
