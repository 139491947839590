import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, useFormikContext } from "formik";

import { states } from "../states";
import classNames from "classnames";

const DateField = ({ item, compositeIndex, compositeParent }) => {
  const { values, handleBlur, handleChange } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item["#states"],
    values
  );

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item["#flex"] ? { flex: item["#flex"] } : {}}
    >
      <label htmlFor={item.id}>
        {item["#title"]}
        {(!!item["#required"] || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>

      <Field
        id={
          compositeParent
            ? `${compositeParent}[${compositeIndex}][${item.id}]`
            : item.id
        }
        name={
          compositeParent
            ? `${compositeParent}[${compositeIndex}][${item.id}]`
            : item.id
        }
        min={item["#date_date_min"]}
        max={item["#date_date_max"]}
        type="date"
        className="form-control"
        onChange={handleChange}
        onBlur={handleBlur}
        value={
          compositeParent
            ? values[compositeParent][compositeIndex][item.id]
            : values[item.id]
        }
        required={(!!item["#required"] || required) && !optional && visible}
        disabled={!enabled || disabled}
      />
      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />
      {!!item["#description"] && (
        <small className="form-description text-muted form-text">
          {item["#description"]}
        </small>
      )}
    </div>
  );
};

DateField.propTypes = {
  item: PropTypes.shape({
    "#date_date_max": PropTypes.string,
    "#date_date_min": PropTypes.string,
    "#default_value": PropTypes.string,
    "#description": PropTypes.string,
    "#flex": PropTypes.number,
    "#maxlength": PropTypes.number,
    "#minlength": PropTypes.number,
    "#placeholder": PropTypes.string,
    "#required": PropTypes.bool,
    "#required_error": PropTypes.string,
    "#states": PropTypes.object,
    "#submit__label": PropTypes.string,
    "#title": PropTypes.string,
    "#type": PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default DateField;
