import React from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import {Link, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

import { trunc } from "../../../lib/shorten-text";

import EditButton from "../../../backend/edit-button";
import getEncodedIcalUrl from "../../../lib/get-encoded-ical-url";
import Tags from "../../tags/tags";
import classNames from "classnames";
import RandomPlaceholderImage from "@general-components/random-placeholder-image";
import DateTime from "@general-components/date-time/date-time";
import {encodeUrl} from "@lib/encode-url";

/**
 * @todo use "FieldTime"
 */
const TeaserEventHighlighted = (props) => {
  const intl = useIntl();
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const location = useLocation();
  const hasImage = props.item?.fieldImage?.fieldMediaImage;

  const teaserClassNames = classNames({
    "node node-teaser node-teaser-default teaser-event-highlighted": true,
   [`node-${props.item.id}`]: true,
   "with-image": hasImage,
  });

  return (
    <article
      className={teaserClassNames}
      data-color-scheme={encodeUrl(props.item.fieldEventType?.name)}
    >
      <EditButton
        adminApp={adminApp}
        entityId={props.item.id}
        destinationRoute={location.pathname}
      />
      {props.item?.fieldImage?.fieldMediaImage ? (
        <Image
          data={props.item.fieldImage.fieldMediaImage}
          nodeTitle={props.item.title}
        />
      ) : (
        <RandomPlaceholderImage
          type={"event-background"}
          classNames={"event-placeholder-background"}
        />
      )}
      <Link to={props.item.url.path} className="container bigger">
        <div className="row">
          <div className="col-16 teaser-infos-wrapper">
            <h2 className="headline-lg">
              {props.item.title}
            </h2>

            <div className="date">
              <DateTime
                value={props.item.fieldDate.value}
                endValue={props.item.fieldDate.endValue}
                granularity="datetime"
                mode="short"
                showEnd={true}
              />
              {props.item.fieldLocation &&
                <>
                  {" | "}{props.item.fieldLocation?.locality}
                </>
              }
            </div>

            {!hasImage && props.item.fieldText &&
              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: props.item.fieldText }}
              />
            }
          </div>
        </div>
      </Link>
    </article>
  );
};

export const teaserEventPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldLocation: PropTypes.string,
  fieldShowDuration: PropTypes.bool,
  fieldText: PropTypes.string.isRequired,
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  fieldDate: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldTicket: PropTypes.shape({
    uri: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

TeaserEventHighlighted.propTypes = {
  item: teaserEventPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default TeaserEventHighlighted;
