import React, { useState } from "react";
import Share from "@general-components/static-modules/share/share";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormattedMessage} from "react-intl";
import Modal from 'react-modal';

const ShareElement = ({ content }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  return (
    <>
      <div className="icon-wrapper">
        <FontAwesomeIcon icon="fa-solid fa-share" />
      </div>

      <button onClick={() => openModal()}>
        <FormattedMessage id="share.label" />
      </button>

      <Modal
        isOpen={modalIsOpen}
        contentLabel="Share Modal"
        className="modal--share"
      >
        <button onClick={() => closeModal()}>
          schließen
        </button>
        <Share nodeContent={content} />
      </Modal>
    </>
  );
}

export default ShareElement;