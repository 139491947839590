import React from "react";
import { self } from "@js/config";
import { FormattedMessage, useIntl } from "react-intl";
import toast from "@general-components/ui/toast";

const Share = ({ nodeContent }) => {
  const intl = useIntl();

  const url = self + nodeContent?.url.path;

  const shareSubject = intl.formatMessage({ id: "share.subject" });
  const shareMessage = intl.formatMessage({ id: "share.body" }, { url });

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    toast({
      message: "Link wurde zur Zwischenablage hinzugefügt.",
      type: "success"
    })
  };

  const shareViaEmail = () => {
    window.location.href =
      `mailto:user@example.com?subject=${encodeURIComponent(shareSubject)}&body=${encodeURIComponent(shareMessage)}`;
  };

  const shareViaLinkedin = () => {
    window.open(
      `https://www.linkedin.com/feed/?shareActive=true&url=${url}&title=${encodeURIComponent(shareSubject)}&text=${encodeURIComponent(shareMessage)}`
    );
  }

  return (
    <section className="share">
      <div className="button-wrapper">
        <button className="btn btn-primary" onClick={shareViaLinkedin}>
          <FormattedMessage id="share.via_linkedin" />
        </button>

        <button className="btn btn-primary" onClick={copyToClipboard}>
          <FormattedMessage id="share.via_link" />
        </button>

        <button className="btn btn-primary" onClick={shareViaEmail}>
          <FormattedMessage id="share.via_email" />
        </button>
      </div>
    </section>
  );
};

export default Share;
