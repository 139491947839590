import React from "react";

const CustomArrow = ({ orientation }) => {
  return (
    <div
      className={"custom-icon arrow"}
      data-orientation={orientation}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        fill="none"
        viewBox="0 0 45 45"
      >
        <path fill="#E50447" d="m22.5 0-4.09 4.09 15.486 15.488H0v5.845h33.896L18.409 40.909 22.5 45 45 22.5z"/>
      </svg>
    </div>
  );
}

export default CustomArrow;