import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../../error-boundary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Arrow from "../../../../assets/icons/arrow-right.svg";

/**
 * Recursive menu component that calls itself inside again if there
 * are subitems.
 * The level variable will count up to identify the nested layers.
 */
const RecursiveMenu = ({ items, level = 1, itemIcon, ulClassName = "", liClassName = "" }) => (
  <ul className={`level-${level} ${ulClassName}`}>
    {items.map((item, i) => {
      const externalUrl = item.link.url.__typename === "ExternalUrl";
      return (
        <li key={i} className={liClassName}>
          <Link
            to={item.link.url.path}
            title={item.link.description || item.link.label}
            className={externalUrl ? 'external' : 'internal'}
            target={externalUrl ? '_blank' : '_self'}
          >
            {item.link.label.toLowerCase() === "login" &&
              <FontAwesomeIcon icon="fa-solid fa-user" />
            }

            {externalUrl &&
              <img src={Arrow} className="arrow-back" alt={"back to Homepage"}/>
            }

            {itemIcon} {item.link.label}
          </Link>
          {/* Recursively call menu component again if there are subitems */}
          {!!item.subtree?.length && (
            <ErrorBoundary>
              <RecursiveMenu items={item.subtree} level={level + 1} />
            </ErrorBoundary>
          )}
        </li>
      );
    })}
  </ul>
);

RecursiveMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.shape({
          path: PropTypes.string,
        }),
      }),
      subTree: RecursiveMenu.propTypes,
    })
  ),
  level: PropTypes.number,
  itemIcon: PropTypes.object,
};

export default RecursiveMenu;
