import React from "react";
import moment from "moment";
import StatusInfo from "@js/intern/pages/components/card-submissions/status-info";
import {EditButton} from "@js/intern/pages/components/card-submissions/edit-button";
import {Link} from "react-router-dom";

export const Submission = ({ submission }) => {
  return (
    <Link
      className="list-group-item list-group-item-action"
      to={`/intern/submit-event/${submission.uuid}`}
      key={submission.uuid}
    >
      <div className="d-flex justify-content-between align-items-center">
        <span>{submission.data.title}</span>
        <small>
          Eingereicht am{" "}
          {moment(submission.created)
            .local()
            .format("dd, DD.MM.YY, HH:mm")}
        </small>
        <div className="grouped-elements">
          <StatusInfo status={"accepted"}/>
          <EditButton/>
        </div>
      </div>
    </Link>
  );
}