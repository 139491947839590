import React from "react";
import PropTypes from "prop-types";

import ErrorBoundary from "../../error-boundary";
import ParagraphSwitch from "./paragraph-switch";
import InPageNavigation from "./in-page-navigation/in-page-navigation";
import VisibilitySensor from "react-visibility-sensor";

const machineName = function (paragraph, position) {
  const identifier = paragraph
    ? `${paragraph.entityBundle}_${paragraph.fieldHeading}_${position}`
    : `unnamed_group_${position}`;
  return identifier.replace(/\W/g, "-");
};

const getGroupId = (paragraphGroup) => {
  const baseField =
    paragraphGroup?.section?.fieldAnchor ||
    paragraphGroup?.section?.fieldHeading;
  if (!baseField) {
    return "";
  }
  return baseField.toLowerCase().replace(/\W/g, "-");
};

const ContentBase = (props) => {
  const paragraphGroups = props.content.reduce((tmpGroups, contentItem) => {
    if (contentItem?.entity?.entityBundle === "section_heading") {
      tmpGroups.push({ section: contentItem.entity, items: [] });
    } else if (tmpGroups.length === 0) {
      tmpGroups.push({ items: [] });
    }
    const currentGroup = tmpGroups[tmpGroups.length - 1];
    currentGroup.items.push(contentItem);

    return tmpGroups;
  }, []);

  return (
    <section className={`content-wrap ${props.className}`}>
      {/*props.useInpageNavi && (
        <InPageNavigation
          paragraphGroups={paragraphGroups}
          getGroupId={getGroupId}
        />
      )*/}

      {paragraphGroups.map((paragraphGroup, paragraphGroupIndex) => {
        const groupAttributes = {
          className: "paragraph-group",
          "data-paragraph-group": machineName(
            paragraphGroup?.section,
            paragraphGroupIndex
          ),
        };
        const groupId = getGroupId(paragraphGroup);
        if (groupId) {
          groupAttributes.id = groupId;
        }
        return (
          <VisibilitySensor
            partialVisibility={true}
            minTopValue={ typeof window !== "undefined" ? window.innerHeight * 0.33 : 200 }
            offset={{
              top: 0
            }}
            once={false}
          >
            {({ isVisible }) => (
              <div
                key={paragraphGroupIndex}
                {...groupAttributes}
                data-is-visible={isVisible}
              >
                {paragraphGroup.items.map((item, index) => (
                  <ErrorBoundary key={index}>
                    {
                      // eslint-disable-next-line complexity
                      (() => {
                        let paragraphItem = item.entity ? item.entity : item;

                        if (paragraphItem?.entityBundle === "from_library") {
                          paragraphItem =
                            item.entity.fieldReusableParagraphRawField.first.entity
                              .paragraphsRawField.first.entity;
                        }

                        return (
                          <ParagraphSwitch
                            paragraphItem={paragraphItem}
                            nodeContent={props.nodeContent}
                          />
                        );
                      })()
                    }
                  </ErrorBoundary>
                ))}
              </div>
            )}
          </VisibilitySensor>
        );
      })}
    </section>
  );
};

ContentBase.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        fieldReusableParagraph: PropTypes.object,
        entityBundle: PropTypes.string.isRequired,
        fieldManuelleSortierung: PropTypes.bool,
      }),
    })
  ),
  className: PropTypes.string,
  nodeContent: PropTypes.object,
  useInpageNavi: PropTypes.bool,
};

export default ContentBase;