import React from "react";
import { Outlet } from "react-router-dom";
import useAuthentication from "../use-authentication";
import CardMemberActions from "./components/card-member-actions";
import CardUser from "./components/card-user";

const InternLayout = () => {
  const { auth } = useAuthentication();

  const currentUser = auth?.currentUser;
  const loggedIn = auth.userLoginData.access_token !== undefined;

  /**
   * Layout for system authentication pages (login, forgot password, reset password).
   */
  if (!loggedIn) {
    return <Outlet />;
  }

  /**
   * Layout for internal pages.
   */
  return (
    <article className="intern intern-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-16">
            <div className="card dashboard-head">
              <div className="card-header">
                <h1>Hallo {currentUser?.name}.</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-16 col-lg-4">
            <CardMemberActions />
            <CardUser />
          </div>
          <div className="col-16 col-lg-12">
            <Outlet />
          </div>
        </div>
      </div>
    </article>
  );
};

InternLayout.propTypes = {};

export default InternLayout;
