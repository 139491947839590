import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const LocationInfo = ({ content }) => {
  if (content.fieldLocation) {
    return (
      <>
        {content.fieldLocation && (
          <div className={"location-info"}>
            <div className="icon-wrapper">
              <FontAwesomeIcon icon="fa-solid fa-location-dot" />
            </div>

            <span>
            {content.fieldLocation?.locality}
          </span>
          </div>
        )}
      </>
    );
  }
}

export default LocationInfo;