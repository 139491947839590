import React from "react";
import PropTypes from "prop-types";
import ErrorBoundary from "../../../../../error-boundary";
import TeaserEventHighlighted, {
  teaserEventPropTypes,
} from "../../../../teaser-base/event/teaser-event-highlighted";
import Slider from "react-slick";

const TeaserListEventsHighlighted = ({ content, nodes }) => {
  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    touchMove: true,
  };

  if (nodes.length > 1) {
    return (
      <div className={"highlighted-events"}>
        <ErrorBoundary>
          <Slider {...sliderSettings}>
            {!!nodes.length &&
              nodes.map((item, i) => <TeaserEventHighlighted key={i} item={item} />)
            }
          </Slider>
        </ErrorBoundary>
      </div>
    );
  }
};

TeaserListEventsHighlighted.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldLimit: PropTypes.number,
    fieldContentType: PropTypes.oneOf(["event"]),
    fieldTags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    fieldNodes: PropTypes.arrayOf(teaserEventPropTypes),
    fieldMode: PropTypes.oneOf(["auto", "manual", "highlights"]),
  }),
};

export default TeaserListEventsHighlighted;
