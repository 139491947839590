import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {encodeUrl} from "@lib/encode-url";

const Tags = ({ tags }) => {
  // Support single Tag Values
  if (!Array.isArray(tags)) {
    tags =   [tags];
  }

  tags = tags?.filter((tag) => tag !== null) || [];

  if (!tags.length) return;

  return (
    <div className="tags-wrapper">
      {tags.map((tag, i) => {
        const tagClassNames = classNames({
          "tag": true,
          [ encodeUrl(tag.name) ]: true
        });

        return (
          <span
            key={i}
            className={tagClassNames}
            data-tag-value={encodeUrl(tag.name)}
            data-color-scheme={encodeUrl(tag.name)}
          >
          {tag.name}
        </span>
        );
      })}
    </div>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
};

export default Tags;
