import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ErrorBoundary from "../../../../../error-boundary";
import TeaserEvent, {
  teaserEventPropTypes,
} from "../../../../teaser-base/event/teaser-event";
import teaserNodeQueryEventTagFiltered from "../../../../teaser-base/queries/teaser-node-query-event-tag-filtered.graphql";
import { useQuery } from "@apollo/client";
import moment from "moment";
import TeaserListEventsHighlighted from "@paragraphs/teaser-list/events/teaser-list-events-highlighted";
import TeaserListEventsBasic from "@paragraphs/teaser-list/events/teaser-list-events-basic";

const ParagraphTeaserListEvents = ({ content }) => {
  // get automaticall filtered nodes
  const { data } = useQuery(teaserNodeQueryEventTagFiltered, {
    skip: content.fieldMode === "manual",
    variables: {
      date: moment().format("YYYY-MM-DD"),
      limit: content.fieldLimit || 100,
      type: [content.fieldContentType],
      tags: content.fieldTags.map((item) => item.id.toString()),
      filterTagEnabled: !!content.fieldTags.length && content.fieldMode !== "highlights",
      filterHighlightsEnabled: content.fieldMode === "highlights",
    },
  });

  const nodes =
    content.fieldMode !== "manual"
      ? data?.entityQuery?.items || []
      : content.fieldNodes;

  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-list": true,
    [`paragraph-teaser-list-${content.fieldLimit}`]: true,
    [`paragraph-teaser-list-${content.fieldContentType}`]: true,
    [`paragraph-teaser-list-${content.fieldMode}`]: true,
  });

  return (
    <section className={sectionClassNames}>
      {content.fieldHeading && (
        <div className="container">
          <div className="row">
            <div className="col-16">
              <h2>{content.fieldHeading}</h2>
            </div>
          </div>
        </div>
      )}

      {content.fieldMode === "highlights" ? (
        <TeaserListEventsHighlighted
          content={content}
          nodes={nodes}
        />
      ) : (
        <TeaserListEventsBasic
          content={content}
          nodes={nodes}
        />
      )}
    </section>
  );
};

ParagraphTeaserListEvents.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldLimit: PropTypes.number,
    fieldContentType: PropTypes.oneOf(["event"]),
    fieldTags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    fieldNodes: PropTypes.arrayOf(teaserEventPropTypes),
    fieldMode: PropTypes.oneOf(["auto", "manual", "highlights"]),
  }),
};

export default ParagraphTeaserListEvents;
