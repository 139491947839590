import React from "react";
import LoadingIndicator from "../general-components/loading-indicator";
import { SocialMedia } from "./components/social-media";
import { Menu } from "../general-components/navigation/menu";
import useConfigQuery from "../hooks/use-config-query/use-config-query";
import Error from "../system/error";
import moment from "moment";
import UserStatusBlock from "@js/intern/components/user-status-block";
import { NetworkError } from "@system/exceptions";

const PageFooter = () => {
  const {
    data: footerData,
    loading,
    error,
  } = useConfigQuery(["footer", "social_media"]);

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    throw new NetworkError();
  }

  /**
   * The footer and social_media configs are requested at the same time so
   * they will be stored in an array called "items" (see useConfigQuery).
   */
  const [footerConfig, socialMediaConfig] = footerData.items;

  return (
    <footer id="page-footer">
      <div className="container">
        {footerConfig && (
          <>
            {/*!!footerConfig.fieldSitemap?.links.length && (
              <div className="row footer-sitemap">
                <div className="col-16">
                  <Menu menuTree={footerConfig.fieldSitemap} />
                </div>
              </div>
            )*/}
            {/*socialMediaConfig && (
              <div className="row footer-social-media">
                <SocialMedia content={socialMediaConfig} />
              </div>
            )*/}
            <div className="row footer-legal">
              <div className="col-16">
                {!!footerConfig.fieldLegalMenu?.links.length && (
                  <Menu menuTree={footerConfig.fieldLegalMenu} />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </footer>
  );
};

PageFooter.propTypes = {};

export default PageFooter;
