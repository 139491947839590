import ParagraphForm from "@paragraphs/webform/paragraph-form";
import React from "react";
import { useLoaderData } from "react-router-dom";
import webformSubmissionQuery from "./webform-submission.graphql";
import { NotFound } from "@system/exceptions";

/**
 * Loader function for the PageSubmitEvent component.
 * This function fetches the submission data for the event submission.
 * @see https://reactrouter.com/en/main/route/loader
 *
 * @param {ApolloClient} client
 * @param {object} params
 */
export const pageSubmitEventLoader = async (client, params) => {
  const { eventSubmissionUuid } = params;

  // If no event submission ID is provided, return null.
  if (!eventSubmissionUuid) {
    return null;
  }

  // Fetch the submission data for the event submission.
  const data = await client.query({
    query: webformSubmissionQuery,
    variables: {
      uuid: eventSubmissionUuid,
    },
  });

  // If no data is returned, throw a NotFound exception.
  // This will be handled by the errorElement prop in the route configuration.
  if (!data?.data?.entityByUuid) {
    throw new NotFound();
  }

  return data.data;
};

const PageSubmitEvent = () => {
  const data = useLoaderData();

  const submissionData = data?.entityByUuid?.encodedData
    ? JSON.parse(data?.entityByUuid.encodedData)
    : null;

  return (
    <div className="card">
      <div className="card-header">
        <h3>Termin einreichen</h3>
      </div>
      <div className="card-body">
        <ParagraphForm
          content={{
            fieldFormRawField: { first: { targetId: "event_submission" } },
          }}
          defaultInitialValues={submissionData}
          submissionId={data?.entityByUuid?.id || null}
        />
      </div>
    </div>
  );
};

export default PageSubmitEvent;
