import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import { FormattedMessage } from "react-intl";
import { videoEmbedPropTypes } from "../paragraph-video";
import { Play } from "@carbon/icons-react";
import CustomPlay from "@general-components/ui/custom-icons/custom-play";

const VideoEmbedded = ({ content }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const videoUrl = content?.fieldMediaOembedVideo;

  // if no video url is set, don't render
  if (!videoUrl) return;

  // get the provider from the url
  const getProvider = (url) => {
    const youTubePossibleStrings = ["youtube", "youtu.be"];
    if (youTubePossibleStrings.some((string) => url.includes(string)))
      return "youtube";

    const vimeoPossibleStrings = ["vimeo"];
    if (vimeoPossibleStrings.some((string) => url.includes(string)))
      return "vimeo";
  };

  const provider = getProvider(videoUrl);

  const videoId = videoUrl
    .replace("https://www.youtube.com/watch?v=", "")
    .replace("https://youtu.be/", "");

  const thumbnail = content.thumbnail;

  return (
    <div className={"embedded-video"}>
      {!showVideo && (
        <>
          <div className="preview">
            {!showVideo && !showInfo && (
              <div
                className="playpause"
                onClick={() => setShowInfo(true)}
                tabIndex={0}
                role={"button"}
                aria-label={"play video"}
              >
                <CustomPlay/>
              </div>
            )}
            <div className="dsgvo-thumbnail youtube">
              {thumbnail && (
                <img
                  src={thumbnail.style.urlPath}
                  alt={thumbnail.alt || "Video Thumbnail"}
                />
              )}
            </div>
          </div>
          {showInfo && (
            <div className="privacy-info-banner">
              <div className="accept-info">
                {provider === "youtube" && (
                  <FormattedMessage id="gdpr.youtube" />
                )}
                {provider === "vimeo" && <FormattedMessage id="gdpr.vimeo" />}
              </div>
              <div className="accept-actions">
                <Link
                  to={"/page/datenschutzerklaerung"}
                  className="btn btn-secondary"
                >
                  <FormattedMessage id="learn_more" />
                </Link>
                <a
                  tabIndex={0}
                  role="button"
                  aria-label="accept privacy infos"
                  className="btn btn-primary"
                  onClick={() => {
                    setShowVideo(true);
                    setShowInfo(false);
                  }}
                >
                  <FormattedMessage id="okay" />
                </a>
              </div>
            </div>
          )}
        </>
      )}
      {showVideo && (
        <div className={`iframe-wrap ${provider === "youtube" ? "iframe-16-9 youtube" : "vimeo"}`}>
          {provider === "youtube" && (
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube-nocookie.com/embed/${videoId}${
                showVideo ? "?autoplay=1" : ""
              }`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              rel={0}
              allowFullScreen
              className="iframe-youtube"
            />
          )}
          {provider === "vimeo" && (
            <Vimeo
              video={content.fieldMediaOembedVideo}
              responsive
              autoplay
              controls={true}
            />
          )}
        </div>
      )}
    </div>
  );
};

VideoEmbedded.propTypes = {
  content: PropTypes.shape(videoEmbedPropTypes),
};

export default VideoEmbedded;
