import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";
import { LinkPropType } from "../../../link/link";
import CustomGalleryArrow from "./../../../ui/custom-icons/custom-gallery-arrow";
import Slider from "react-slick";

const ParagraphLogosPartners = ({ content }) => {
  const sliderSettings = {
    arrows: true,
    dots: false,
    infinite: false,
    centerPadding: "32px",
    centerMode: false,
    nextArrow: <CustomGalleryArrow classNames={classNames} orientation={"right"}/>,
    prevArrow: <CustomGalleryArrow classNames={classNames} orientation={"left"}/>,
    slidesToScroll: 1,
    slidesToShow: content.fieldSmall ? 6 : 4,
    touchMove: true,
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: content.fieldSmall ? 4.5 : 3.5
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: content.fieldSmall ? 2.5 : 1.5
        },
      }
    ],
  };

  const sectionClassNames = classNames({
    "paragraph paragraph-logos-partners": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16">
            <div className="heading">{content.fieldHeading}</div>
          </div>
        </div>
      </div>

      <div className="container logos">
        <div className="row">
          <div
            className={classNames({
              "col-16": true
            })}
          >
            <Slider {...sliderSettings}>
              {content.fieldLogosPartners.map((item, index) => {
                const PartnerContent = ({ content }) => {
                  return (
                    <div className={"partner-content"}>
                      <Image data={content.fieldMediaImage}/>
                    </div>
                  );
                };

                return (
                  <div
                    key={index}
                    className={classNames({
                      partner: true,
                      "col-8 col-md-6":
                        content.fieldLogosPartners.length !== 3,
                      "col-5": content.fieldLogosPartners.length === 3,
                      "has-link": item.fieldLink !== null,
                    })}
                  >
                    {item.fieldLink !== null ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={item.fieldLink.uri.path}
                      >
                        <PartnerContent content={item}/>
                      </a>
                    ) : (
                      <ErrorBoundary>
                        <PartnerContent content={item}/>
                      </ErrorBoundary>
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphLogosPartners.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldLogosPartners: PropTypes.arrayOf(
      PropTypes.shape({
        fieldLink: LinkPropType,
        fieldMediaImage: PropTypes.shape({
          style: PropTypes.shape({
            url: PropTypes.string,
          }).isRequired,
          styleFullPage: null,
          desktop: null,
          mobile: null,
        }),
      })
    ),
  }),
};

export default ParagraphLogosPartners;
