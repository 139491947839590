import React from "react";
import PropTypes from "prop-types";
import AddToCalendar from "@paragraphs/event-information/components/add-to-calendar";
import ShareElement from "@paragraphs/event-information/components/share-element";
import EventInfoDetailedTime from "./event-info-detailed-time";
import EventInfoDetailedLocation from "./event-info-detailed-location";
import EventInfoTicket from "./event-info-ticket";
import EventInfoLivestream from "./event-info-livestream";
import {FormattedMessage} from "react-intl";


const EventInfoBlock = ({ nodeContent }) => {

  return (
    <section className="event-info-block">
      <div className="container">
        <div className="row">
          <div className="col-16">
            <h4 className="headline-lg">
              <FormattedMessage id={"event.overview"}/>
            </h4>
          </div>
        </div>
        <div className="grid-wrapper">
          <EventInfoDetailedTime content={nodeContent}/>
          <EventInfoDetailedLocation content={nodeContent}/>
          <EventInfoTicket content={nodeContent}/>
          <EventInfoLivestream content={nodeContent}/>

          <div className="grid-element share adjusting-colors">
            <ShareElement content={nodeContent}/>
          </div>
          <div className={"grid-element atc adjusting-colors"}>
            <AddToCalendar content={nodeContent} />
          </div>
        </div>
      </div>
    </section>
  );
};

export const teaserEventPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldLocation: PropTypes.string,
  fieldShowDuration: PropTypes.bool,
  fieldText: PropTypes.string.isRequired,
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  fieldDate: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldTicket: PropTypes.shape({
    uri: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

EventInfoBlock.propTypes = {
  item: teaserEventPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default EventInfoBlock;
