import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";
import { getValueFromStringObjPath } from "../webform-elements";
import { useQuery } from "@apollo/client";
import { webformOptionsQuery } from "../webform-options.graphql";
import YAML from "yaml";

const SelectField = ({ item, compositeIndex, compositeParent }) => {
  const { values, handleChange, handleBlur } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item["#states"],
    values
  );

  const { data, loading, error } = useQuery(webformOptionsQuery, {
    variables: {
      id: item["#options"],
    },
    skip: !item["#options"] || typeof item["#options"] !== "string",
  });

  const value = compositeParent
    ? compositeIndex
      ? getValueFromStringObjPath(values, compositeParent)[compositeIndex][
          item.id
        ]
      : getValueFromStringObjPath(values, compositeParent)[item.id]
    : values[item.id];

  const identifier = compositeParent
    ? compositeIndex
      ? `${compositeParent}[${compositeIndex}][${item.id}]`
      : `${compositeParent}[${item.id}]`
    : item.id;

  let options = item["#options"];

  if (data?.entityById?.options) {
    options = YAML.parse(data?.entityById?.options);
  }

  console.log(options);
  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item["#flex"] ? { flex: item["#flex"] } : {}}
    >
      <label htmlFor={identifier}>
        {item["#title"]}
        {(!!item["#required"] || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>

      <Field
        id={identifier}
        name={identifier}
        as="select"
        className="form-control"
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        required={(!!item["#required"] || required) && !optional && visible}
        disabled={!enabled || disabled}
        multiple={!!item["#multiple"]}
      >
        {/* @todo Translate */}
        <option
          disabled={(!!item["#required"] || required) && !optional && visible}
          value={item["#empty_value"] ? item["#empty_value"] : ""}
        >
          {" "}
          {item["#empty_option"]
            ? item["#empty_option"]
            : "- Bitte auswählen -"}{" "}
        </option>
        {options &&
          Object.keys(
            options).map((key, value) => (
              <option key={key} value={key}>
                {options[key]}
              </option>
            )
          )}
      </Field>

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />

      {!!item["#description"] && (
        <small className="form-description text-muted form-text">
          {item["#description"]}
        </small>
      )}
    </div>
  );
};

SelectField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    "#states": PropTypes.object,
    "#flex": PropTypes.number,
    "#title": PropTypes.string,
    "#required": PropTypes.bool,
    "#description": PropTypes.string,
    "#multiple": PropTypes.number,
    "#multiple_error": PropTypes.string,
    "#options": PropTypes.objectOf(PropTypes.string),
    "#empty_option": PropTypes.string,
    "#empty_value": PropTypes.string,
  }),
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default SelectField;
