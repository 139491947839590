import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import {isOnlineEvent} from "@lib/is-online-event";

const OnlineInfo = ({ content }) => {

  if (isOnlineEvent(content)) {
    return (
      <div className="location-info">
        <div className="icon-wrapper">
          <FontAwesomeIcon icon="fa-solid fa-globe" />
        </div>

        <span>
        <FormattedMessage id={"event.online"}/>
      </span>
      </div>
    );
  }
}

export default OnlineInfo;