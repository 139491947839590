import React from "react";
import PropTypes from "prop-types";

// Event Placeholder Images
import EventBackground01 from "./../../assets/event-backgrounds/bsd-event-background-01.jpg";

/*
* This Component is getting a Random Image from a pool of imported ressources.
*/

const RandomPlaceholderImage = ({ type, classNames }) => {
  let randomImageUrl = "",
    randomPool = [];

  const eventBackgrounds = [
    EventBackground01
  ]

  switch (type) {
    case "event-background":
      randomPool = eventBackgrounds;
  }

  randomImageUrl = randomPool[Math.floor(Math.random()*randomPool.length)];

  return (
    <img
      src={randomImageUrl}
      className={classNames}
      alt={"Zufälliger Platzhalter im BuildingSMART Design."}
    />
  );
}

RandomPlaceholderImage.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
};

export default RandomPlaceholderImage;