import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//import {Tooltip, TooltipGroup} from "@general-components/tooltip";

const StatusInfo = ({ status }) => {

  // Map backend Status to Icons
  const statusOptions = {
    "denied": {
      icon: "fa-xmark",
      tooltip: "Die Einreichung wurde abgelehnt."
    },
    "accepted": {
      icon: "fa-check",
      tooltip: "Die Einreichung wurde akzeptiert."
    },
    "pending": {
      icon: "fa-spinner",
      tooltip: "Die Einreichung wird bearbeitet."
    },
    "needs-review": {
      icon: "fa-triangle-exclamation",
      tooltip: "Die Einreichung benötigt Ihre Bearbeitung."
    }
  };

  /*return (
    <TooltipGroup>
      <Tooltip content={statusOptions[status]?.tooltip}>
        <div
          className="status-info icon"
          data-color-scheme={status}
          role="status"
        >
          <FontAwesomeIcon icon={`fa-solid ${statusOptions[status]?.icon}`} />
        </div><div
        className="status-info icon"
        data-color-scheme={status}
        role="status"
      >
        <FontAwesomeIcon icon={`fa-solid ${statusOptions[status]?.icon}`} />
      </div>
      </Tooltip>
    </TooltipGroup>
  );*/

  return (
    <>
      <div
        className="status-element status-icon"
        data-color-scheme={status}
        role="status"
      >
        <FontAwesomeIcon icon={`fa-solid ${statusOptions[status]?.icon}`} />
      </div>
    </>
  );
}

export default StatusInfo;