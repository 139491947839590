import React from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import {Link, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

import { trunc } from "../../../lib/shorten-text";

import EditButton from "../../../backend/edit-button";
import getEncodedIcalUrl from "../../../lib/get-encoded-ical-url";
import Tags from "../../tags/tags";
import RandomPlaceholderImage from "@general-components/random-placeholder-image";
import LocationInfo from "@paragraphs/event-information/components/location-info";
import DateTime from "@general-components/date-time/date-time";

const TeaserEvent = (props) => {
  const intl = useIntl();
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const location = useLocation();

  return (
    <article
      className={`node node-${props.item.id} node-teaser node-teaser-default teaser-event related col-16`}
    >
      <EditButton
        adminApp={adminApp}
        entityId={props.item.id}
        destinationRoute={location.pathname}
      />
      <Link className="row" to={props.item.url.path}>
        <div className="col-16 content">
          <div className="img-wrapper">
            {props.item?.fieldImage?.fieldMediaImage ? (
              <Image
                data={props.item.fieldImage.fieldMediaImage}
                nodeTitle={props.item.title}
              />
            ) : (
              <RandomPlaceholderImage
                type={"event-background"}
                classNames={"event-placeholder-background"}
              />
            )}
          </div>

          <div className="infos">
            <div className="date">
              <DateTime
                value={props.item.fieldDate.value}
                endValue={props.item.fieldDate.endValue}
                granularity="datetime"
                mode="short"
                showEnd={true}
              />
            </div>

            <h3>{props.item.title}</h3>
            <LocationInfo content={props.item}/>
          </div>
        </div>
      </Link>
    </article>
  );
};

export const teaserEventPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldLocation: PropTypes.string,
  fieldShowDuration: PropTypes.bool,
  fieldText: PropTypes.string.isRequired,
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  fieldDate: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldTicket: PropTypes.shape({
    uri: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

TeaserEvent.propTypes = {
  item: teaserEventPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default TeaserEvent;
