import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import QueryExtendedTeaserView from "../query-overview.graphql";

// Create the overview context.
export const OverviewContext = React.createContext({});

// The OverviewProvider makes the context store accessible to children.
const OverviewProvider = ({ children, content }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentFilters, setCurrentFilters] = useState({
    title: "",
    field_tags_target_id: [],
    field_event_type_target_id: [],
    combine: "",
  });
  const [metaData, setMetaData] = useState({
    totalRows: 1,
    perPage: 30,
  });

  // The view ID is used to fetch the correct view.
  const viewId = `overview_${content.fieldContentType}`;

  const variables = {
    viewId,
    type: content.fieldContentType ? [content.fieldContentType] : null,
    page: currentPage,
    ...currentFilters,
  };

  // Fetch nodes based on current filters.
  // @see https://graphql-core-schema.netlify.app/schema-extensions/views.html
  const { data, loading, error, fetchMore } = useQuery(
    QueryExtendedTeaserView,
    {
      variables,
      // Update the loading state when the query is refetched.
      notifyOnNetworkStatusChange: true,
      // Update metadata when the query is completed.
      onCompleted: (data) => {
        setMetaData({
          totalRows: data.entityById.executable.execute.total_rows,
          perPage: data.entityById.executable.pager.perPage,
        });
      },
    }
  );

  // Extract nodes from the result.
  const nodes = data?.entityById.executable.execute.rows;

  // The context value provides all necessary values and functions.
  const value = {
    ...content,
    viewId,
    metaData,
    currentPage,
    setCurrentPage,
    currentFilters,
    setCurrentFilters,
    fetchMore,
    nodes,
    loading,
    error,
  };

  return (
    <OverviewContext.Provider value={value}>
      {children}
    </OverviewContext.Provider>
  );
};

OverviewProvider.propTypes = {
  children: PropTypes.node,
  content: PropTypes.shape({
    fieldContentType: PropTypes.oneOf(["event", "news", "project", "person"]),
  }),
};

export default OverviewProvider;
