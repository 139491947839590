import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import EditButton from "../../../backend/edit-button";
import { teaserEventPropTypes } from "./teaser-event";
import DateTime from "@general-components/date-time/date-time";
import Tags from "@general-components/tags/tags";
import LocationInfo from "@paragraphs/event-information/components/location-info";
import CustomArrow from "@general-components/ui/custom-icons/custom-arrow";
import CheckForChildren from "@lib/check-for-children";
import OnlineInfo from "@paragraphs/event-information/components/online-info";
import ErrorBoundary from "@js/error-boundary";

/**
 * @todo use <FieldDate>
 */
const TeaserEventTeaserlist = ({ item }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const location = useLocation();

  return (
    <article
      className={`node node-${item.id} node-teaser teaser-event teaser-list`}
    >
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />

      <Link
        className="flex-wrap"
        to={item.url ? item.url.path : item.path.alias}
      >
        <div
          className={classNames({
            row: true,
          })}
        >
          <div
            className={classNames({
              "col-16 col-lg-4 meta-infos": true,
            })}
          >
            <ErrorBoundary>
              <DateTime
                value={item?.fieldDate?.value}
                endValue={item.fieldDate?.endValue}
                granularity="datetime"
                mode="short"
                showEnd={true}
              />
            </ErrorBoundary>
            <CheckForChildren classes={"location-wrapper"}>
              <LocationInfo content={item}/>
              <OnlineInfo content={item}/>
            </CheckForChildren>

            <Tags tags={item.fieldEventType} />
          </div>

          <div
            className={classNames({
              "col-16 col-lg-11 main-infos": true,
            })}
          >

            <h3 className="headline-md">
              {item.title}
            </h3>
            {item.fieldText &&
              <div
                className="text teaser-text"
                dangerouslySetInnerHTML={{
                  __html: `${item.fieldText}`,
                }}
              />
            }
          </div>

          <div className="d-none d-lg-flex col-lg-1 arrow">
            <CustomArrow orientation={"right"}/>
          </div>
        </div>
      </Link>
    </article>
  );
};

TeaserEventTeaserlist.propTypes = {
  item: teaserEventPropTypes,
};

export default TeaserEventTeaserlist;
