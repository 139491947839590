import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";

const EventInfoTicket = ({ content }) => {
  if (content?.fieldLivestream) {
    return (
      <a
        className="grid-element livestream not-bold adjusting-colors"
        href={content?.fieldLivestream?.uri?.path}
        target={"_blank"}
      >
        <div className="icon-wrapper">
          <FontAwesomeIcon icon="fa-solid fa-video" />
        </div>

        <div className="text">
          <strong>
            <FormattedMessage id="livestream.top" />
          </strong>
          <div>
            <FormattedMessage id="livestream.bottom" />
          </div>
        </div>
      </a>
    );
  }
}

export const teaserEventPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldLocation: PropTypes.string,
  fieldShowDuration: PropTypes.bool,
  fieldText: PropTypes.string.isRequired,
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  fieldDate: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldTicket: PropTypes.shape({
    uri: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

EventInfoTicket.propTypes = {
  item: teaserEventPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default EventInfoTicket;