import React from "react";
import PropTypes from "prop-types";
import ErrorBoundary from "../../../../../error-boundary";
import TeaserEvent, {
  teaserEventPropTypes,
} from "../../../../teaser-base/event/teaser-event";

const TeaserListEventsBasic = ({ content, nodes }) => {
  return (
    <div className="container">
      <div className="row">
        <ErrorBoundary>
          {!!nodes.length &&
            nodes.map((item, i) => <TeaserEvent key={i} item={item} />)}
        </ErrorBoundary>
      </div>
    </div>
  );
};

TeaserListEventsBasic.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldLimit: PropTypes.number,
    fieldContentType: PropTypes.oneOf(["event"]),
    fieldTags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    fieldNodes: PropTypes.arrayOf(teaserEventPropTypes),
    fieldMode: PropTypes.oneOf(["auto", "manual", "highlights"]),
  }),
};

export default TeaserListEventsBasic;
