import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import getEncodedIcalUrl from "@lib/get-encoded-ical-url";
import {FormattedMessage} from "react-intl";

const AddToCalendar = ({ content }) => {
  return (
    <>
      <div className="icon-wrapper">
        <FontAwesomeIcon icon="fa-solid fa-calendar-plus" />
      </div>

      <a
        href={getEncodedIcalUrl(
          content.fieldDate,
          content.fieldLocation || "",
          content.title,
          content.fieldText || ""
        )}
        className="body-m fw-500"
      >
        <span>
          <FormattedMessage id="event.remember" />
        </span>
      </a>
    </>
  );
}

export default AddToCalendar;