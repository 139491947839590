import React from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { gql, useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import Select from "react-select";
import classNames from "classnames";
import {encodeUrl} from "@lib/encode-url";


const TaxonomyFilter = ({ item }) => {
  const { setFieldValue, handleBlur, values } = useFormikContext();
  const TAXONOMY_QUERY = gql`
    query TaxonomyQuery($vid: String = "tags") {
      entityQuery(
        entityType: TAXONOMY_TERM
        filter: { conditions: [{ field: "vid", value: [$vid] }] }
        limit: 100
      ) {
        items {
          label
          id
        }
      }
    }
  `;

  // const fieldIdentifier = item.options.id;
  const fieldIdentifier = item.options.expose.identifier;

  // Get taxonomy terms.
  const { loading, error, data } = useQuery(TAXONOMY_QUERY, {
    variables: {
      vid: item.options.vid,
    },
  });

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-8 offset-md-1">
            <h3>
              <FormattedMessage id="error.general" />
            </h3>
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </div>
        </div>
      </div>
    );
  }
  const terms = data.entityQuery.items.filter((item) => !!item?.label);

  return (
    <FieldArray
      name={fieldIdentifier}
      render={(arrayHelpers) => (
        <div className="filter-options">
          {terms.map((tag) => (
            <>
              {tag?.label !== " " &&
                <label
                  key={tag.id}
                  data-option-value={tag.label}
                  className={classNames({
                    active: values[fieldIdentifier]?.includes(tag.id),
                    "selectable": true,
                  })}
                >
                  <input
                    name={fieldIdentifier}
                    type="checkbox"
                    value={tag}
                    data-color-scheme={encodeUrl(tag.label).toLowerCase()}
                    checked={values[fieldIdentifier]?.includes(tag.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        arrayHelpers.push(tag.id);
                      } else {
                        const idx = values[fieldIdentifier].indexOf(tag.id);
                        arrayHelpers.remove(idx);
                      }
                    }}
                  />
                  <span>
                    {tag.label}
                  </span>
                </label>
              }
            </>
          ))}
        </div>
      )}
    />
  );
};

TaxonomyFilter.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.shape({
      id: PropTypes.string,
      vid: PropTypes.string,
      expose: PropTypes.shape({
        label: PropTypes.string,
        placeholder: PropTypes.string,
        multiple: PropTypes.bool,
      }),
    }),
  }),
};

export default TaxonomyFilter;
